<template>
<PageWithHeader>
  <template #title>
    <t path="">Instruction</t>
  </template>
  <div class="v-content py-32">
    <t as="p" path="visa_2">Step 1. Select the country, please scroll down the page</t>
    <Pic src="trade/visa_1" class="mt-8" style="width: 100%"/>
    <t as="p" path="visa_3" class="mt-32">Step 2. Select the country, please scroll down the page</t>
    <Pic src="trade/visa_2" class="mt-8" style="width: 100%"/>
    <t as="p" path="visa_4" class="mt-32">Step 2. Select the country, please scroll down the page</t>
    <Pic src="trade/visa_3" class="mt-8" style="width: 100%"/>
    <t as="p" path="visa_5" class="mt-32">Step 2. Select the country, please scroll down the page</t>
    <Pic src="trade/visa_4" class="mt-8" style="width: 100%"/>
    <t as="p" path="visa_6" class="mt-32">Step 2. Select the country, please scroll down the page</t>
    <Pic src="trade/visa_5" class="mt-8" style="width: 100%"/>
  </div>
</PageWithHeader>
</template>

<script>
import { defineComponent } from 'vue'
import PageWithHeader from '@/provider/PageWithHeader.vue'
import Pic from '@/components/Pic.vue'
export default defineComponent({
  name: 'VisaMasterIntro',
  components: {
    PageWithHeader,
    Pic,
  },
})
</script>

<style lang="sass" scoped>

</style>
